"use client";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { IconButton, InputBase, Stack } from "@mui/material";
import {
  StopCircleOutlined as StopIcon,
  LockOutlined as LockedIcon
} from "@mui/icons-material";
import SendIcon from "@mui-symbols-material/w400/SendFilledRounded";
import SendOutlineIcon from "@mui-symbols-material/w400/SendOutlined";

import { useStore, observer } from "../../../service/mobx";

function Input({ mobile = false, locked }) {
  const [input, setInput] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const { agent, reader, user } = useStore();
  const pathname = usePathname();
  const disabled =
    agent.threadId === undefined ||
    ((pathname.startsWith("/docs") || pathname.startsWith("/read")) &&
      reader.viewing === "paper" &&
      agent.paperHasBeenRead === false);
  const Icon = agent.running
    ? StopIcon
    : locked
    ? LockedIcon
    : disabled || !input
    ? SendOutlineIcon
    : SendIcon;
  const submit = () => {
    if (locked) {
      locked();
    } else {
      setInput("");
      agent.chat(input);
    }
  };

  usePaper(pathname, setPlaceholder);

  return (
    <Stack
      height={56}
      spacing={1}
      position="sticky"
      top={0}
      zIndex={2}
      borderRadius={theme => theme.shape.xl.top}
    >
      <Stack
        height="100%"
        direction="row"
        justifyContent="space-between"
        sx={theme => ({
          px: 2,
          py: 1,
          bgcolor: disabled
            ? "secondary.container"
            : mobile
            ? "surface.container.highest"
            : "surface.container.lowest",
          borderRadius: theme.shape.round,
          transition: theme.transitions.create("all")
        })}
      >
        {/* <LevelIcon /> */}
        <InputBase
          fullWidth
          value={input}
          disabled={disabled}
          placeholder={placeholder}
          sx={theme => ({
            typography: "titleMd",
            color: "surface.on.color",
            ".Mui-disabled": {
              fontStyle: "italic",
              WebkitTextFillColor: theme.palette.secondary.on.container
            }
          })}
          inputProps={{ sx: { color: "surface.on.color" } }}
          onChange={event => setInput(event.target.value)}
          onKeyUp={event => {
            if (event.key === "Enter" && input) {
              submit();
            }
          }}
        />
        <IconButton
          aria-label="Submit"
          disabled={agent.running === false && (disabled || input === "")}
          onClick={() => {
            if (agent.running) {
              const [{ runId }] = agent.history;

              fetch(
                `/api/agent?threadId=${user.profile.threadId}&runId=${runId}`,
                { method: "PATCH" }
              );
              agent.set.running(false);
            } else {
              submit();
            }
          }}
        >
          <Icon
            sx={{
              color: input || agent.running ? "primary.color" : undefined
            }}
          />
        </IconButton>
      </Stack>
      {/* <Suggestions /> */}
    </Stack>
  );
}

export default observer(Input);

// const LevelIcon = observer(function LevelIcon() {
//   const { agent } = useStore();
//   const levels = [
//     ["Normal", NoviceIcon],
//     ["Developer", MidIcon],
//     ["Scientist", AdvIcon]
//   ];
//   const [label, Icon] = levels[agent.level];

//   return (
//     <ToolTip
//       placement="right"
//       title={`Change technical level. Level ${agent.level + 1} - ${label}`}
//     >
//       <IconButton
//         size="small"
//         onClick={() =>
//           agent.set.level(agent.level + 1 === 3 ? 0 : agent.level + 1)
//         }
//         sx={{
//           "&:hover svg": {
//             color: "primary.color"
//           }
//         }}
//       >
//         <Icon sx={{ color: "outline.variant" }} />
//       </IconButton>
//     </ToolTip>
//   );
// });
function usePaper(pathname, setSpeech) {
  const { agent, reader } = useStore();
  // const isDiscover = pathname === "/";
  const isPaperPage =
    pathname.startsWith("/docs/") || pathname.startsWith("/read/");

  useEffect(() => {
    if (isPaperPage) {
      if (agent.paperHasBeenRead) {
        setSpeech(
          "Have a question on " + reader.paperNode?.title?.split(":")[0] + "?"
        );
      } else if (reader.viewing === "paper") {
        setSpeech("Reading the paper for you...");
      } else {
        setSpeech("Ask Bytez about AI...");
      }
    } else {
      setSpeech("Ask Bytez about AI...");
    }
  }, [reader, setSpeech, isPaperPage, reader.viewing, agent.paperHasBeenRead]);
}
