import dynamic from "next/dynamic";
import { useCallback, useEffect, useState } from "react";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import {
  CheckRounded as CheckIcon,
  Close as CloseIcon
} from "@mui/icons-material";

import LimitedIcon from "@mui-symbols-material/w400/CloseSmallRounded";

import { useStore, observer } from "../../../../service/mobx";
import ButtonFilled from "../../../Button/Filled";

const Confetti = dynamic(() => import("react-confetti"));

export default function useDialogUpgrade(
  cta = "You've reached our free-tier monthly limit"
) {
  const { dialog } = useStore();
  const useDialogUpgrade = useCallback(() => {
    dialog.configure({
      Content: () => <Content cta={cta} />,
      props: { fullWidth: true, maxWidth: "expanded" },
      sx: {
        paper: {
          p: 0,
          width: undefined,
          maxHeight: undefined,
          bgcolor: "dark.surface.container.lowest"
        }
      }
    });
  }, [dialog, cta]);

  return useDialogUpgrade;
}
const Content = observer(function Content({ cta }) {
  const [tabInFocus, setTabInFocus] = useState(true);
  const [upgradeUrl, setUrl] = useState();
  const { dialog, device, user, utilities } = useStore();
  const plansToShow = device.isPhone ? [plans[1]] : plans;

  useEffect(() => {
    if (user.syncedWithStripe) {
      utilities
        .stripe("PUT", JSON.stringify({ upgrade: true }))
        .then(async res => {
          const { url } = await res.json();

          setUrl(url);
        });
    }
  }, [utilities, user.syncedWithStripe]);

  useEffect(() => {
    dialog.set.sx({
      paper: {
        p: 0,
        width: undefined,
        maxHeight: undefined,
        bgcolor: user.premium
          ? "colors.success.50"
          : "dark.surface.container.lowest",
        transition: theme => theme.transitions.create("all", { duration: 10e3 })
      }
    });

    if (user.premium) {
      const celebrate = () =>
        setTabInFocus(document.visibilityState === "visible");

      document.addEventListener("visibilitychange", celebrate);
      return () => document.removeEventListener("visibilitychange", celebrate);
    }
  }, [dialog, user.premium]);

  return tabInFocus && user.premium ? (
    <Stack alignItems="center" justifyContent="center" height="500px">
      <Confetti style={{ maxHeight: "100%", width: "100%" }} />
      <Typography variant="displayLg" color="colors.success.90">
        Premium unlocked 🥳
      </Typography>
      <Typography variant="titleLg" color="colors.success.99">
        Thank you for being a supporter. Enjoy unlimited access
      </Typography>
    </Stack>
  ) : (
    <Box
      textAlign="center"
      py={{ compact: 1, expanded: 3 }}
      px={{ compact: 1, expanded: 10 }}
    >
      {device.isPhone ? (
        <Stack pt={2} direction="row" justifyContent="flex-end">
          <IconButton onClick={dialog.toggle}>
            <CloseIcon sx={{ color: "dark.surface.on.color" }} />
          </IconButton>
        </Stack>
      ) : null}
      <Typography
        align="center"
        color="dark.tertiary.color"
        typography={{ compact: "headlineMd", expanded: "headlineLg" }}
        sx={{ textWrap: "balance", pt: 4 }}
      >
        Upgrade for unlimited access
      </Typography>
      <Typography
        align="center"
        color="dark.tertiary.color"
        sx={{
          textWrap: "balance",
          pb: 4,
          typography: { compact: "titleMd", expanded: "titleLg" }
        }}
      >
        {cta}
      </Typography>
      <Stack direction={device.isPhone ? "column" : "row"} spacing={3}>
        {plansToShow.map((plan, index) => (
          <Plan key={index} plan={plan} upgradeUrl={upgradeUrl} />
        ))}
      </Stack>
    </Box>
  );
});
function Plan({
  upgradeUrl,
  plan: { title, description, features = [], cta, price }
}) {
  // const [clicked, setClicked] = useState(false);
  // const { dialog, user } = useStore();
  const premium = title === "Premium";

  return (
    <Box
      flex={1}
      border={0.5}
      // boxShadow={theme=}
      // borderColor={premium ? "dark.primary.color" : undefined}
      overflow="hidden"
      sx={theme => ({
        borderRadius: theme.shape.xl.round,
        boxShadow: premium
          ? `${theme.palette.alpha(
              theme.palette.dark.primary.on.container,
              0.2
            )} 0px 2px 1px -1px, ${theme.palette.alpha(
              theme.palette.dark.primary.on.container,
              0.15
            )} 0px 1px 1px 0px,${theme.palette.alpha(
              theme.palette.dark.primary.on.container,
              0.12
            )} 0px 1px 3px 0px`
          : undefined
      })}
    >
      <Box
        // textAlign="center"
        p={3}
        // border={1}
        // borderColor="dark.outline.variant"
        bgcolor={
          premium ? "dark.primary.container" : "dark.surface.container.low"
        }
        sx={theme => ({ borderRadius: theme.shape.xl.top })}
      >
        <Typography
          pb={3}
          typography={{ compact: "headlineLg", expanded: "titleLg" }}
          color={
            premium ? "dark.primary.on.container" : "dark.surface.on.color"
          }
        >
          {title}
        </Typography>
        <Typography
          paragraph
          pb={2}
          variant="bodyLg"
          sx={{ textWrap: "balance" }}
          color={
            premium ? "dark.primary.on.container" : "dark.surface.on.color"
          }
        >
          {description}
        </Typography>
        <Box height={56}>
          {price === undefined ? null : (
            <>
              <Typography
                component="span"
                variant="displayMd"
                color="dark.surface.on.color"
                fontWeight={200}
              >
                ${price}
              </Typography>
              <Typography
                component="span"
                variant="bodyLg"
                color="dark.surface.on.variant"
              >
                {" "}
                / month
              </Typography>
            </>
          )}
        </Box>
        <Box pt={3}>
          {cta ? (
            <ButtonFilled
              fullWidth
              href={upgradeUrl}
              label={cta}
              target="_blank"
              sx={{
                height: 56,
                borderRadius: theme => theme.shape.md.round,
                typography: "titleLg",
                color: "dark.primary.color",
                bgcolor: "dark.primary.on.color",
                transition: theme => theme.transitions.create("all"),
                "&:hover": {
                  bgcolor: "dark.primary.color",
                  color: "dark.primary.on.color"
                }
              }}
            />
          ) : (
            <Box height={32} />
          )}
        </Box>
      </Box>
      <Stack
        p={3}
        spacing={3}
        height="100%"
        alignItems="flex-start"
        justifyContent="flex-start"
        bgcolor="dark.surface.color"
        sx={theme => ({ borderRadius: theme.shape.xl.bottom })}
      >
        {features.map(({ features }, index) => (
          <div key={index}>
            {/* <Typography
              component="div"
              align="left"
              typography={{ compact: "titleLg", expanded: "headlineSm" }}
              color="dark.primary.color"
              sx={{ textWrap: "balance" }}
            >
              {title}
            </Typography>
            <Typography
              paragraph
              align="left"
              variant="bodyLg"
              color="dark.secondary.color"
            >
              {description}
            </Typography> */}
            <Stack useFlexGap spacing={1}>
              {features.map(text => (
                <Stack
                  useFlexGap
                  spacing={2}
                  key={text}
                  alignItems="center"
                  direction="row"
                >
                  {text.includes("Limited") ? (
                    <LimitedIcon
                      sx={{
                        color: theme =>
                          theme.palette.alpha(
                            theme.palette.dark.surface.on.color,
                            0.3
                          )
                      }}
                    />
                  ) : (
                    <CheckIcon sx={{ color: "dark.surface.on.color" }} />
                  )}

                  <Typography
                    align="left"
                    variant="bodyMd"
                    color="dark.surface.on.color"
                  >
                    {text}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </div>
        ))}
      </Stack>
    </Box>
  );
}
const plans = [
  {
    title: "Free",
    description: "Free, limited access to premium features",
    // cta: "Stay forever free",
    // price: 0,
    features: [
      {
        // title: "Papers",
        // description: "Interactive AI papers",
        features: [
          "Unlimited reading",
          "Limited access to paper audio",
          "Limited access to AI Research Agent"
        ]
      }
      // {
      //   title: "Research Assistant: Papers",
      //   description: "Aware of 2 million AI papers, including new papers",
      //   features: [
      //     "Find papers using natural language",
      //     "Ask research questions, get factual answers with citations",
      //     "Ask about a paper, get summaries, key insights, and more"
      //   ]
      // }

      // [
      //   "Models API",
      //   ["API key", "Run paper models", "Limited access to 10,000+ models"]
      // ]
    ]
  },
  {
    title: "Premium",
    description: "Find and understand papers quickly",
    cta: "Get unlimited",
    price: 10,
    features: [
      {
        // title: "Papers",
        // description: "Interactive AI papers",
        features: [
          "Unlimited reading",
          "Unlimited paper audio",
          "Unlimited AI Research Agent",
          "Unlimited paper summaries, key insights, and more"
        ]
      }
      // {
      //   title: "Research Assistant: Papers",
      //   description: "Aware of 2 million AI papers, including new papers",
      //   features: [
      //     "Find papers using natural language",
      //     "Ask research questions, get factual answers with citations",
      //     "Ask about a paper, get summaries, key insights, and more"
      //   ]
      // }

      // [
      //   "Models API",
      //   ["API key", "Run paper models", "Limited access to 10,000+ models"]
      // ]
    ]
  }
  // {
  // title: "Developer",
  // description: "Everything in researcher, plus:",
  // cta: "Chat with us"
  // price: 100,
  // features: [
  //   {
  //     title: "Models",
  //     description: "Run models in your cloud, browser, or via API",
  //     features: [
  //       "Early access: demo a paper's model",
  //       "Access to thousands of models"
  //     ]
  //   },
  //   {
  //     title: "Research Assistant: Models",
  //     description: "Aware of 300,000 models and growing daily",
  //     features: [
  //       "Early access",
  //       "Find the right models easily",
  //       "Can code for you: generates pipelines by combining models"
  //     ]
  //   }

  //   // [
  //   //   "Models API",
  //   //   ["API key", "Run paper models", "Limited access to 10,000+ models"]
  //   // ]
  // ]
  // }
  // {
  //   title: "Pro",
  //   cta: "Chat with us",
  //   href: "https://meetings.hubspot.com/bytez/holly-nawar",
  //   // price: "Custom",
  //   description: "Best for professionals",
  //   features: [
  //     ["AI Research Agent", ["Unlimited access"]],
  //     ["Papers", ["Unlimited listening to papers"]]
  //     // ["Models API", ["Unlimited access to >100,000 models"]]
  //   ]
  // }
  // {
  //   title: "Developer",
  //   cta: "Chat with founders",
  //   price: "Custom",
  //   // description: "Best for AI professionals",
  //   features: [
  //     ["AI Research Agent", ["Unlimited access"]],
  //     ["Papers", ["Unlimited listening to papers"]],
  //     ["Models API", ["Unlimited access to >100,000 models"]]
  //   ]
  // }
];

// export default function useSignInDialog(cta = "Welcome") {
//   const { analytics, dialog, device, user } = useStore();

//   return () => {
//     analytics.track.event("User Auth");
//     dialog.configure({
//       sx: {
//         paper: {
//           bgcolor: "dark.surface.container.lowest"
//           // width: 1024,
//           // minHeight: "80vh"
//         }
//       },
//       Content() {
//         return (
//           <>
//             {device.isPhone ? (
//               <Stack pt={2} direction="row" justifyContent="flex-end">
//                 <IconButton onClick={dialog.toggle}>
//                   <CloseIcon sx={{ color: "dark.surface.on.color" }} />
//                 </IconButton>
//               </Stack>
//             ) : null}
//             <Stack
//               spacing={2}
//               alignSelf="center"
//               alignItems="center"
//               justifyContent="center"
//               pt={3}
//               maxWidth={480}
//             >
//               <Typography
//                 pb={1}
//                 align="center"
//                 variant="headlineSm"
//                 color="dark.primary.color"
//                 sx={{ textWrap: "balance" }}
//               >
//                 {cta}
//               </Typography>
//               <Stack spacing={1}>
//                 <Grid
//                   container
//                   alignItems="center"
//                   justifyContent="center"
//                   spacing={1}
//                 >
//                   {[
//                     ["Save content", SaveIcon],
//                     ["Run models", ModelIcon],
//                     ["Tune your feed", FeedIcon],
//                     ["Free API key", KeyIcon]
//                   ].map(([label, Icon]) => (
//                     <Grid
//                       item
//                       key={label}
//                       compact={6}
//                       display="flex"
//                       justifyContent="center"
//                     >
//                       <LabelWithIcon dark label={label} Icon={Icon} />
//                     </Grid>
//                   ))}
//                 </Grid>
//               </Stack>
//               <Stack
//                 spacing={1}
//                 justifyContent="center"
//                 alignItems="center"
//                 width="100%"
//               >
//                 <Typography variant="labelLg" color="dark.surface.on.color">
//                   Sign in
//                 </Typography>
//                 {[
//                   {
//                     label: "Google",
//                     Icon: GoogleIcon,
//                     onClick: user.sign.in.google
//                   },
//                   {
//                     label: "Discord",
//                     Icon: DiscordIcon,
//                     onClick: user.sign.in.google
//                   }
//                 ].map(({ label, Icon, onClick }, index) => (
//                   <Button
//                     fullWidth
//                     key={index}
//                     variant="filled"
//                     onClick={() => {
//                       onClick();
//                       dialog?.set.open(false);
//                     }}
//                     startIcon={<Icon sx={{ ml: 1, width: 18, height: 18 }} />}
//                     sx={theme => ({
//                       height: 40,
//                       bgcolor: "dark.primary.container",
//                       borderRadius: theme.shape.round,
//                       pr: 3,
//                       color: "dark.primary.on.container",
//                       typography: "labelLg",
//                       transition: theme.transitions.create("all"),
//                       "&:hover": {
//                         color: "dark.primary.on.color",
//                         bgcolor: "dark.primary.color"
//                       }
//                     })}
//                   >
//                     {label}
//                   </Button>
//                 ))}
//               </Stack>
//               <Typography
//                 variant="bodySm"
//                 align="center"
//                 color="dark.secondary.color"
//                 sx={{
//                   textWrap: "balance",
//                   "& a": {
//                     color: "inherit",
//                     textDecoration: "none"
//                   },
//                   "& a:hover": {
//                     textDecoration: "underline"
//                   }
//                 }}
//               >
//                 By continuing you agree to{" "}
//                 <a href="/legal/terms" target="_blank">
//                   terms of service
//                 </a>{" "}
//                 and{" "}
//                 <a href="/legal/privacy" target="_blank">
//                   privacy policy
//                 </a>
//               </Typography>
//             </Stack>
//           </>
//         );
//       }
//     });
//   };
// }
